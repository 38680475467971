<template>
  <b-modal
    id="printer-modal"
    title="Editar"
    size="lg"
    cancel-variant="danger"
    ok-title="Aceptar"
    cancel-title="Cancelar"
    @ok="printTag"
    @cancel="resetVals"
    @close="resetVals"
    @hide="resetVals"
  >
    <template v-if="toPrint">
      <div>
        <b-button
          class="mr-1"
          variant="success"
          @click="cambio('valvula');tagPrefix = !isBIO ? 'SV' : 'XV-'"
        >
          VALVULA
        </b-button>
        <b-button
          variant="success"
          @click="cambio();tagPrefix = 'MM'"
        >
          MOTOR
        </b-button>
      </div>
      <p
        v-if="tagModificado"
        class="text-8xl inline-block my-4 w-full text-center"
        style="letter-spacing:1rem"
      >
        {{ tagPrefix }}{{ tagModificado }}
      </p>
      <ul
        v-if="!tagModificado"
        class="text-center my-4"
      >
        <li
          v-for="(l, k) in myArr(toPrint.tag)"
          :key="k"
          class="text-8xl inline-block pl-1"
        >
          {{ l }}
        </li>
      </ul>
      <b-form-input
        v-model="toPrint.desc"
        class="mb-2"
        name="descripcion"
        style="font-size:22px;height:50px;padding: 26px;"
      />
      <h4
        v-if="isBIO && toPrint.ubicacion != 'SP' && toPrint.ubicacion != 'FB' && toPrint.ubicacion != 'DeviceBox' "
        class="text-center text-2xl mb-2"
      >
        {{ toPrint.armario }} | {{ toPrint.ubicacion }} | Canal {{ Number(toPrint.ch) }}
      </h4>
      <h4
        v-else-if="isBio && toPrint.ubicacion == 'SP' || toPrint.ubicacion == 'FB' || toPrint.ubicacion == 'DeviceBox'"
        class="text-center text-2xl mb-2"
      >
        {{ toPrint.armario }} | {{ toPrint.ubicacion }}{{ Number(toPrint.slot)+1 }} | SPUR {{ Number(toPrint.ch) + 1 }}
      </h4>
      <h4
        v-if="!isBIO"
        class="text-center text-2xl mb-2"
      >
        AR {{ toPrint.armario }} Slot {{ toPrint.slot }} Ch. {{ toPrint.ch }}
      </h4>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BButton,
    BFormInput,
  },
  props: {
    toPrint: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tagModificado: null,
      tagPrefix: null,
      isBIO: false,
      descPrint: '',
    }
  },
  watch: {
    toPrint() {
      const BIO = this.toPrint.armario
      this.isBIO = BIO.startsWith('BIO')
      console.log(this.toPrint)
      if (this.isBIO && this.toPrint.ubicacion !== 'SP' && this.toPrint.ubicacion !== 'FB' && this.toPrint.ubicacion !== 'DeviceBox') {
        this.descPrint = `${this.toPrint.armario} | ${this.toPrint.ubicacion} | Canal ${Number(this.toPrint.ch)}`
      } else if (this.isBIO && (this.toPrint.ubicacion === 'SP' || this.toPrint.ubicacion === 'FB' || this.toPrint.ubicacion === 'DeviceBox')) {
        this.descPrint = `${this.toPrint.armario} | ${this.toPrint.ubicacion}${Number(this.toPrint.slot) + 1} | SPUR ${Number(this.toPrint.ch) + 1}`
      }
      if (!this.isBIO) {
        this.descPrint = `AR ${this.toPrint.armario} Slot ${this.toPrint.slot} Ch. ${this.toPrint.ch}`
      }
    },
  },
  methods: {
    myArr(text) {
      if (text) {
        return text.split('')
      }
      return false
    },
    async printTag() {
      let { desc } = this.toPrint
      desc = this.quitarAcentos(desc)
      desc = desc.toUpperCase()
      const tag = this.tagModificado ? `${this.tagPrefix}${this.tagModificado}` : this.toPrint.tag
      if (tag) {
        const myDesc = desc.replaceAll('#', 'No.')
        let parseURL = `http://10.62.1.155/requests.php?action=imprimir&tag=${tag}&desc=${myDesc}&ar=${this.descPrint}`
        const objPrint = {
          tag,
          desc: myDesc,
          ar: this.toPrint.armario,
          slot: this.toPrint.slot,
          ch: this.toPrint.ch,
        }
        parseURL = encodeURI(parseURL)
        await fetch(parseURL, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
      }
    },
    cambio() {
      let { tag } = this.toPrint
      tag = tag.toUpperCase()
      tag = tag.includes('ZEA') ? tag.split('ZEA') : tag
      tag = tag.includes('ZEV') ? tag.split('ZEV') : tag
      tag = tag.includes('ZEC') ? tag.split('ZEC') : tag
      tag = tag.includes('ZEH') ? tag.split('ZEH') : tag
      tag = tag.includes('ZEP') ? tag.split('ZEP') : tag
      // eslint-disable-next-line prefer-destructuring
      this.tagModificado = tag[1]
    },
    resetVals() {
      this.tagModificado = null
      this.tagPrefix = null
    },
    quitarAcentos(cadena) {
      const acentos = {
        á: 'a', é: 'e', í: 'i', ó: 'o', ú: 'u', Á: 'A', É: 'E', Í: 'I', Ó: 'O', Ú: 'U',
      }
      return cadena.split('').map(letra => acentos[letra] || letra).join('').toString()
    },
  },
}
</script>
