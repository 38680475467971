<template>
  <b-card>
    <div class="custom-search">
      <!-- advance search input -->
      <b-row>
        <b-col md="4">
          <b-form-group
            class="mb-2 searchInput"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filterText"
                type="search"
                placeholder="Buscar..."
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filterText"
                  variant="gradient-primary"
                  @click="search = true"
                >
                  Buscar
                </b-button>
                <b-button
                  :disabled="!filterText"
                  variant="gradient-primary"
                  @click="search = false;filter='';filterText=''"
                >
                  Limpiar
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-table
      class="capitalize text-center"
      striped
      hover
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :items="rows"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn"
      empty-text="No se encontrarón resultados"
      @filtered="onFiltered"
    >
      <template #cell(actions)="data">
        <b-button
          size="sm"
          variant="primary"
          @click="$bvModal.show('printer-modal'); toPrintData = JSON.parse(JSON.stringify(data.item)); "
        >
          <feather-icon
            icon="PrinterIcon"
            size="16"
          />
        </b-button>
      </template>
    </b-table>
    <printer :to-print="toPrintData" />
    <div>
      <b-pagination
        v-model="currentPage"
        :total-rows="rowsCount ? rowsCount : totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </div>
  </b-card>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BRow, BCol, BCard, BTable, BInputGroupAppend, BButton, BInputGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import firebase from 'firebase/app'
import printer from './Components/PrinterModal.vue'
// import 'firebase/database'
import api from '@/../apis/Calibraciones'

export default {
  components: {
    BPagination,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BTable,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    printer,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      dir: false,
      totalRows: 0,
      searchTerm: '',
      // Table
      perPage: 10,
      pageOptions: [3, 5, 10, 15, 20, 25, 50, 100],
      currentPage: 1,
      filter: null,
      filterOn: [],
      rowsCount: null,
      fields: [
        {
          label: 'Tag',
          key: 'tag',
        },
        {
          label: 'Descripción',
          key: 'desc',
        },
        {
          label: 'Armario',
          key: 'armario',
        },
        {
          label: 'Point',
          key: 'mx',
        },
        {
          label: 'Acciones',
          key: 'actions',
        },
      ],
      rows: [],
      search: false,
      filterText: '',
      toPrintData: {},
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    search() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      if (this.search) {
        this.filter = this.filterText
      }
    },
  },
  created() {
    api.actions.obtenerElencos().then(snap => {
      this.rows = []
      const result = snap.data.data
      Object.keys(result).forEach(element => {
        // const ip = result[element].IP
        const armario = result[element].name
        // const location = result[element].ubicacion
        const slotsQ = result[element].slotsqty
        const slotsAll = result[element].slots
        for (let index = 0; index < slotsQ; index += 1) {
          const noCanales = slotsAll[index].canales
          const allCanales = slotsAll[index].channels
          // const tipoCanal = slotsAll[index].tipo
          const slotN = slotsAll[index].slot
          const ubicacion = slotsAll[index].modelo
          for (let j = 0; j < noCanales; j += 1) {
            let { tag } = allCanales[j]
            tag = tag.toUpperCase()
            if (tag !== 'RESERVA' && !tag.startsWith('ZSA') && !tag.startsWith('ZSC') && !tag.startsWith('ZSI') && !tag.startsWith('ZSR')) {
              const singleCanal = {}
              singleCanal.desc = allCanales[j].desc
              singleCanal.tag = allCanales[j].tag
              singleCanal.mx = `Sl${slotN}Ch${j}`
              singleCanal.slot = slotN
              singleCanal.ch = j
              singleCanal.armario = armario
              singleCanal.ubicacion = ubicacion
              singleCanal.id = element + armario + slotN + j
              this.rows.push(singleCanal)
            }
          }
        }
      })
      this.totalRows = this.rows.length
    })
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.rowsCount = filteredItems.length
      this.currentPage = 1
      this.search = false
    },
  },
}
</script>
